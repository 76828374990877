@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap");
body {
	font-family: "Poppins", "Arial Narrow", Arial, sans-serif;
	background: white;
	color: #1a1200;
}

.hero {
	background-image: url("assets/img/hero-image.svg");
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
}

.radial-gradient-end {
	background: radial-gradient(at bottom right, #aaa8a1 60%, #1a1200 10%);
}

.radial-gradient-start {
	background: radial-gradient(at top left, #1a1200 20%, #664700 20% 25%, #1a1200 10%);
}

.our-chapters {
	background: url("assets/img/our-chapters.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.our-domains {
	background: url("assets/img/domains.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.our-executives {
	background: url("assets/img/executives.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.card-gradient {
	background: radial-gradient(at bottom right, #ffa800 10%, white 80%);
	/* background: linear-gradient(to bottom right, transparent 50%, #ffa800); */
}

.radial-gradient-left {
	position: absolute;
	right: 0;
	height: 50rem;
	width: 30rem;
	z-index: 0;
	background: radial-gradient(at center right, #ffaa0074 2%, transparent 40%);
}
